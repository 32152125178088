import React, { } from "react";

function Footer() {

  return (
    <div className="footer pt-2 relative">
      <p className="py-4 mb-0 text-center">
        © Dapo Paul Ogunlana {new Date().getFullYear()} . All right reserved
      </p>
    </div>
  );
}
export default Footer;
