import React, { useState } from "react";

import "./../assets/styles/layout.scss";
import { logo } from "./../assets/images";

import { navigateToId } from "./../utils/utility-services";

function Header() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  return (
    <div
      className={
        openMobileMenu
          ? "header open-mobile-menu"
          : "header close-mobile-menu"
      }
    >
      <div className="sub-layer"></div>
      <div className="case spread-info md-open relative">
        <div className="logo-mini" onClick={() => navigateToId('home')}>
          <img src={logo} alt="" />
        </div>
        <div className="spread-info">
          <div className="mobile-menu pr-2">
            <i className="fas fa-bars" onClick={toggleMobileMenu}></i>
          </div>
        </div>
      </div>
      <div className="w90 spread-nav-web relative">
        <div className="logo md-close-im" onClick={() => navigateToId('home')}>
          <img src={logo} alt="" />
        </div>
        <div className="nav">
                
          <ul>
            <li>
              <div className="text-center">
                <span className="clickable" onClick={() => navigateToId('about_me')}>About Me</span>
              </div>
            </li>
            <li>
              <div className="text-center">
                <span className="clickable" onClick={() => navigateToId('info')}>Portfolio</span>
              </div>
            </li>
            <li>
              <div className="text-center">
                <button className="solid-button-2c rad-25-im" onClick={() => navigateToId('contact_me')}>Contact Me</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Header;
