import React, {
  // useState
} from "react";

import { heroImg, hollow1, bgStar } from '../assets/images';
// import VideoModal from './video-modal';
import TypeIt from "typeit-react";

import "../assets/styles/pages.scss";

function Hero() {


  // const [toggleVideoModal, setToggleVideoModal] = useState(false);

  // const openVid = () => {
  //   setToggleVideoModal(true);
  // }

  // const closeVid = () => {
  //   setToggleVideoModal(false);
  // }

  return (
    <div className="hero" id="home">
      <div className="header-space"></div>
      <div className="hero-spread">
        <div className="star-bg top-left">
          <img src={bgStar} alt="" />
        </div>
        <div className="star-bg top-right">
          <img src={bgStar} alt="" />
        </div>
        <div className="star-bg bottom-left">
          <img src={bgStar} alt="" />
        </div>
        <div className="star-bg bottom-right">
          <img src={bgStar} alt="" />
        </div>
        <div className="star-bg center-star">
          <img src={bgStar} alt="" />
        </div>
        <div className="img-sect">
          <img src={heroImg} alt="" data-aos="zoom-out" data-aos-delay="600" />
        </div>
        <div className="text-sect">
          <div className="hollow-bg">
            <img src={hollow1} alt="" />
          </div>
          <h4 className="relative">Hi, I’m</h4>
          <h1 className="relative">Dapo Paul Ogunlana</h1>
          <p className="relative">
            <TypeIt>
              I’m a Frontend web developer with good experience in UI 
              design and average knowledge of backend development
            </TypeIt>
          </p>
        </div>
      </div>
      {/* {toggleVideoModal && <VideoModal link={'xvNLi8NCM3E'} onCloseModal={closeVid} />} */}
      <h2 className="text-center font-weight-bold">About Me</h2>
    </div>
  );
}
export default Hero;
