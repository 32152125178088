import {
    protoDegen,
    protoDrivin,
    protoDefi,
    protoGoatMarketing,
    protoGoatCore,
    protoDebtAlert,
    protoManila,
    protoCustomz,

    websiteSinverseLicense,
    websiteVanilla,
    websitePlax,
    websiteSinverse,
    websiteTowva,
    websiteGoatMetaverse,
    websiteManillaP2p,
    websiteVendu,
    websiteNftCube,
    websiteDrivin,
} from "../assets/images";



export const websiteList = [
    {
        url: 'https://sinverse.com',
        image: websiteSinverse,
    },
    {
        url: 'http://manilla.finance',
        image: websiteManillaP2p,
    },
    {
        url: 'https://vendu.store',
        image: websiteVendu,
    },
    {
        url: 'https://sinverse-license-office.netlify.app',
        image: websiteSinverseLicense,
    },
    {
        // url: 'https://goatcoin.net',
        url: 'https://goat-marketing-game-mode.netlify.app/',
        image: websiteGoatMetaverse,
    },
    {
        url: 'https://vanilla.network',
        image: websiteVanilla,
    },
    {
        url: 'https://nft-cube.netlify.app',
        image: websiteNftCube,
    },
    {
        url: 'https://drivin.netlify.app/',
        image: websiteDrivin,
    },
    {
        url: 'https://foresight-defi-club.netlify.app',
        image: protoDefi,
    },
    {
        url: 'https://cares.plax.ng',
        image: websitePlax,
    },
    // {
    //     url: 'https://manilla.technology/home',
    //     image: protoManila,
    // },
    {
        url: 'https://towva.netlify.app',
        image: websiteTowva,
    },
    // {
    //     url: 'https://riseonigeria.com/',
    //     image: protoRON,
    // },
    // {
    //     url: 'https://customz.netlify.app',
    //     image: protoCustomz,
    // },
];

export const prototypeList = [
    {
        url: 'https://www.figma.com/proto/ozx3kKJY3jnV44KjvFV8kk/Untitled?type=design&node-id=6-9&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=126%3A1017',
        image: websiteSinverse,
    },
    {
        url: 'https://www.figma.com/proto/dLxLCO8lG7ac1UwfWvhBKl/Degen-Brigade?node-id=111%3A780&scaling=min-zoom&page-id=0%3A1',
        image: protoDegen,
    },
    {
        url: 'https://www.figma.com/proto/JwPqBNTlSDFmp0xKPZetJl/Drivin?node-id=75%3A19',
        image: protoDrivin,
    },
    {
        url: 'https://www.figma.com/proto/Q0V01rFKO8YIkTNJq4ceis/Foresight-club?node-id=6%3A30&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=6%3A30',
        image: protoDefi,
    },
    {
        url: 'https://www.figma.com/proto/07K0zr1L2mrOhNRu6epYux/GOAT-marketing?node-id=408%3A2416&scaling=min-zoom&page-id=408%3A2347&starting-point-node-id=408%3A2416',
        image: protoGoatMarketing,
    },
    {
        url: 'https://www.figma.com/proto/0NS2FyPXypQTbVU2jruYJX/Goat-Core?node-id=4%3A13&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=248%3A5',
        image: protoGoatCore,
    },
    {
        url: 'https://www.figma.com/proto/yeb4QlaRCnPgVd8xDemMcU/Debt-Alert?node-id=1%3A3&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A3',
        image: protoDebtAlert,
    },
    {
        url: 'https://www.figma.com/proto/vaAjFXhkCDemKymq26RABH/Manilla?node-id=1%3A2&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=55%3A3248',
        image: protoManila,
    },
];