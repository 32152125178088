import React, { useEffect } from "react";

import Header from "../../components/header";
import Hero from "../../components/hero";
import About from "../../components/about";
import Info from "../../components/info";
import Footer from "../../components/footer";

import "../../assets/styles/pages.scss";

function HomePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="home">
      <Header />
      <Hero/>
      <About/>
      <Info/>
      <Footer />
    </div>
  );
}
export default HomePage;
