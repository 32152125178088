import React from "react";

import { creativeImage } from '../assets/images';

import "../assets/styles/pages.scss";

function About() {

  return (
    <div className="about pb-5" id="about_me">
      <div className="relative">
        <div className="relative max1200 w96">
          <div className="row py-3">
            <div className="col-md-6 my-3 center-info">
              <div className="w90 max550 imh mobile-fade" data-aos="zoom-in" data-aos-delay="600">
                <img src={creativeImage} alt="" />
              </div>
            </div>
            <div className="col-md-6 my-3">
              <div className="w90 max450 about-card my-4" data-aos="fade-up">
                <h3 className="center-mobile">My Background</h3>
                <p className="center-mobile reduced-soft-web">
                  <b>Personal Development</b><br />
                  I started out as A 3D modeler, designing buildings (environments), and props (both organic and inorganic).
                  <br /><b>Dpcross Design.</b><br />
                  I later moved into 3d character rigging and animation (both character and prop animation), and game level and prefab creation (Unity and Unreal Engine). I Later started developing interest in the web and started Learning UI Design.
                  <br /><b>OKB and Associates LTD</b><br />
                  I started working as a UI designer and frontend developer (Even though I was Hired as just a UI designer), I had to learn frontend development ‘on-the-job’ and very fast.
                  <br /><b>Freelance</b><br />
                  I later left to learn better on my own and spread my tentacles, developing my skills even more.
                  <br /><b>GTC</b><br />
                  For a while now, I’ve been happy building and maintaining apps of different scales and creating designs for new products
                </p>
              </div>
              <div className="w90 max550 imh web-fade" data-aos="zoom-in" data-aos-delay="600">
                <img src={creativeImage} alt="" />
              </div>
              <div className="w90 max450 about-card my-4" data-aos="fade-up">
                <h3 className="center-mobile">Now</h3>
                <p className="center-mobile reduced-soft-web">
                  I have a strong passion for clean, optimized, user-centered and mobile-first responsive applications.
                  I'm experienced in HTML5, CSS3, Javascript and Typescript, and I've worked with Angular for over 5 years and 
                  ReactJS for up to 3 years. I'm never afraid of though algorithms or complex app features, in fact I enjoy 
                  brainstorming a lot, it gives me an excitement of adventure as opposed to just the everyday creation of 
                  components and consumption of APIs.
                  <br /><br />
                  I have good knowledge of UX wireframe creation, UI mid and high fidelity mockup and prototype creation,
                  photo editing and graphics design. I've worked with photoshop for over 5 years and figma for over 3 years now.
                  {/* <br /><br />
                  I have good experience in 3D modeling and animation. I have extensively worked with 3DS max, substance 
                  designer, Iclone, sketchup, etc. but it's been a while now and I just do animation for fun now. 
                  <br /><br />
                  I also have some knowledge of game development which includes level design and prefab creation. I have 
                  worked with Unity3D and Unreal Engine. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
