import React, { useState } from "react";

import { portfolioBgCurve } from '../assets/images';
import { resume } from '../assets/files';
import { goToLink } from '../utils/utility-services';
import { websiteList, prototypeList } from '../utils/temp-data';

import "../assets/styles/pages.scss";

function Info() {

  const [portfolioSwitch, setPortfolioSwitch] = useState('websites');

  const updatePortfolioSwitch = (switcher) => {
    setPortfolioSwitch(switcher);
  }

  return (
    <div className="info" id="info">
      <div className="cv-sect text-center">
        <button className="solid-button-2c" onClick={() => goToLink(resume)}>Download my resume</button>
      </div>
      <div className="relative py-5" id="portfolio">
        <div className="bg-curve">
          <img src={portfolioBgCurve} alt="" />
        </div>
        <div className="bg-fill"></div>
        <h2 className="text-center font-weight-bold">Portfolio</h2>
        <div className="switcher mt-4">
          <div className={'websites ' + (portfolioSwitch === 'websites' ? 'active' : 'dormant')} onClick={() => updatePortfolioSwitch('websites')}>
            <p>Websites</p>
          </div>
          <div className={'prototypes ' + (portfolioSwitch === 'prototypes' ? 'active' : 'dormant')} onClick={() => updatePortfolioSwitch('prototypes')}>
            <p>Prototypes</p>
          </div>
        </div>
        <div className="max1100 w90">
          <div className="row pb-5 pt-3">
            {
              portfolioSwitch === 'websites' ? 
              websiteList.map((item, index) => {
                return <div className="col-lg-4 col-md-6 my-3" key={index}>
                  <div className="portfolio-card" data-aos="zoom-in" data-aos-delay="600">
                    <img src={item.image} alt="" />
                    <button className="solid-button-2c mt-3" onClick={() => goToLink(item.url)}>Visit Website</button>
                  </div>
                </div>
              }) :
              prototypeList.map((item, index) => {
                return <div className="col-lg-4 col-md-6 my-3" key={index}>
                  <div className="portfolio-card" data-aos="zoom-in" data-aos-delay="600">
                    <img src={item.image} alt="" />
                    <button className="solid-button-2c mt-3" onClick={() => goToLink(item.url)}>Preview Prototype</button>
                  </div>
                </div>
              })
            }
          </div>
        </div>
        
      </div>
      <div className="online-links" id="contact_me">
        <h2 className="text-center font-weight-bold">Check Me Online</h2>
        <div className="max1100 w90">
          <div className="row pb-5">
            <div className="col-xl-3 col-md-6 my-3">
              <a href="https://github.com/dapoogunlana" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">Github</span>
                </div>
              </a>
            </div>
            {/* <div className="col-lg-4 col-md-6 my-3">
              <a href="https://gitlab.com/Dapsman2" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">Gitlab</span>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 my-3">
              <a href="https://www.behance.net/dapoogunla764a" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">Behance</span>
                </div>
              </a>
            </div> */}
            <div className="col-xl-3 col-md-6 my-3">
              <a href="https://www.linkedin.com/in/dapo-paul-ogunlana-517618106/" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">LinkedIn</span>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-md-6 my-3">
              <a href="tel:+2347036539719" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">+2347036539719</span>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-md-6 my-3">
              <a href="mailto:dapoogunlana@gmail.com" target="_blank" rel="noreferrer">
                <div className="link-card" data-aos="zoom-in" data-aos-delay="600">
                  <span className="increased-soft">dapoogunlana@gmail.com</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Info;
